<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Suppliers</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Suppliers"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.supplierForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Supplier</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  class="mr-2"
                  v-on="on"
                  :to="{ name: 'module-drum-admin-suppliers-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="suppliers"
        disable-pagination
        hide-default-footer
        no-data-text="There are currently no suppliers saved"
      >
        <template v-slot:item.type="{ item }">
          {{ item.type.charAt(0).toUpperCase() + item.type.slice(1) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.supplierForm.openForm(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Supplier</v-card-title>
        <v-card-text
          >Are you sure you want to delete
          {{ deleteDialog.supplier.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SupplierForm ref="supplierForm" />
  </div>
</template>

<script>
import SupplierForm from "./components/SupplierForm";

export default {
  components: {
    SupplierForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Admin",
          disabled: true,
        },
        {
          text: "Suppliers",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteDialog: {
        open: false,
        loading: false,
        supplier: {},
      },
    };
  },

  computed: {
    suppliers() {
      let suppliers = this.$store.state.drum.admin["suppliers"];

      if (this.searchTerm !== "") {
        suppliers = suppliers.filter((s) => {
          const name = s.name.toLowerCase();
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            s.type.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return suppliers;
    },
  },

  methods: {
    openDelete(supplier) {
      this.deleteDialog.supplier = supplier;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.loading = false;
      this.deleteDialog.open = false;
      this.deleteDialog.supplier = {};
    },

    saveDelete() {
      const appId = this.$route.params.id;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("drum/admin/deleteSupplier", {
          appId,
          supplierId: this.deleteDialog.supplier.id,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },
};
</script>