<template>
  <v-dialog v-model="dialog" max-width="600px" @click:outside="resetForm()">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Supplier
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-form @submit.prevent="saveForm" method="post" id="supplier-form">
          <v-text-field
            label="Name"
            v-model="fields.name"
            ref="supplierName"
            outlined
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-select
            label="Type"
            v-model="fields.type"
            :items="types"
            item-text="name"
            item-value="id"
            outlined
            :error="errors.hasOwnProperty('type')"
            :error-messages="errors['type']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="supplier-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      supplier: {},
      fields: {
        name: "",
        type: "",
      },
      types: [
        {
          id: "transport",
          name: "Transport",
        },
      ],
      errors: {},
    };
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.$nextTick().then(() => {
          this.$refs.supplierName.focus();
        });
      }
    },
  },

  methods: {
    openForm: function (supplier = null) {
      if (supplier !== null) {
        this.isEditing = true;
        this.fields.name = supplier.name;
        this.fields.type = supplier.type;
        this.supplier = supplier;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = "";
      this.fields.type = "";
      this.supplier = {};
      this.errors = {};
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      this.loading = true;
      this.errors = {};

      let payload = {
        appId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.supplierId = this.supplier.id;
      }

      this.$store
        .dispatch("drum/admin/saveSupplier", {
          ...payload,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>